.cropper-container {
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.cropper-container img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  image-orientation: 0deg;
  width: 100%;
  display: block;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
}

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  position: absolute;
  inset: 0;
}

.cropper-wrap-box, .cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: .5;
  background-color: #000;
}

.cropper-view-box {
  height: 100%;
  width: 100%;
  outline: 1px solid #3399ffbf;
  display: block;
  overflow: hidden;
}

.cropper-dashed {
  opacity: .5;
  border: 0 dashed #eee;
  display: block;
  position: absolute;
}

.cropper-dashed.dashed-h {
  height: 33.3333%;
  width: 100%;
  border-top-width: 1px;
  border-bottom-width: 1px;
  top: 33.3333%;
  left: 0;
}

.cropper-dashed.dashed-v {
  height: 100%;
  width: 33.3333%;
  border-left-width: 1px;
  border-right-width: 1px;
  top: 0;
  left: 33.3333%;
}

.cropper-center {
  height: 0;
  opacity: .75;
  width: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cropper-center:before, .cropper-center:after {
  content: " ";
  background-color: #eee;
  display: block;
  position: absolute;
}

.cropper-center:before {
  height: 1px;
  width: 7px;
  top: 0;
  left: -3px;
}

.cropper-center:after {
  height: 7px;
  width: 1px;
  top: -3px;
  left: 0;
}

.cropper-face, .cropper-line, .cropper-point {
  height: 100%;
  opacity: .1;
  width: 100%;
  display: block;
  position: absolute;
}

.cropper-face {
  background-color: #fff;
  top: 0;
  left: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  right: -3px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  top: -3px;
  left: 0;
}

.cropper-line.line-w {
  cursor: ew-resize;
  width: 5px;
  top: 0;
  left: -3px;
}

.cropper-line.line-s {
  cursor: ns-resize;
  height: 5px;
  bottom: -3px;
  left: 0;
}

.cropper-point {
  height: 5px;
  opacity: .75;
  width: 5px;
  background-color: #39f;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  right: -3px;
}

.cropper-point.point-n {
  cursor: ns-resize;
  margin-left: -3px;
  top: -3px;
  left: 50%;
}

.cropper-point.point-w {
  cursor: ew-resize;
  margin-top: -3px;
  top: 50%;
  left: -3px;
}

.cropper-point.point-s {
  cursor: s-resize;
  margin-left: -3px;
  bottom: -3px;
  left: 50%;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  top: -3px;
  right: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  top: -3px;
  left: -3px;
}

.cropper-point.point-sw {
  cursor: nesw-resize;
  bottom: -3px;
  left: -3px;
}

.cropper-point.point-se {
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  width: 20px;
  bottom: -3px;
  right: -3px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: .75;
    width: 5px;
  }
}

.cropper-point.point-se:before {
  content: " ";
  height: 200%;
  opacity: 0;
  width: 200%;
  background-color: #39f;
  display: block;
  position: absolute;
  bottom: -50%;
  right: -50%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  height: 0;
  width: 0;
  display: block;
  position: absolute;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.CropImage-module-0EzIYG-container {
  color: #fff;
  background-color: #000;
}

.Modal-module-UdvQaq-background {
  background-color: #00000026;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Modal-module-UdvQaq-container {
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  flex-direction: column;
  animation-name: Modal-module-UdvQaq-growIn;
  animation-duration: .3s;
  animation-iteration-count: 1;
  display: flex;
}

.Modal-module-UdvQaq-sm {
  width: 460px;
}

.Modal-module-UdvQaq-md {
  width: 700px;
}

.Modal-module-UdvQaq-lg {
  width: 1200px;
}

.Modal-module-UdvQaq-leaving {
  animation-name: Modal-module-UdvQaq-shrinkOut;
  animation-duration: .3s;
  animation-iteration-count: 1;
}

@keyframes Modal-module-UdvQaq-growIn {
  from {
    opacity: 0;
    transform: scale(.01);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes Modal-module-UdvQaq-shrinkOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(.01);
  }
}

@media (prefers-reduced-motion) {
  .Modal-module-UdvQaq-leaving {
    animation-duration: .1s;
  }

  @keyframes Modal-module-UdvQaq-shrinkOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: scale(1);
    }
  }
}

.ModalBody-module-f8HVQW-container {
  flex: 1;
  padding-block: 16px;
  padding-inline: 24px;
  overflow: hidden auto;
}

.ModalFooter-module-diLesG-container {
  border-top: var(--border);
  flex-direction: row-reverse;
  gap: 8px;
  padding-block: 8px;
  padding-inline: 24px;
  display: flex;
}

/*# sourceMappingURL=CropperModal.f2c7ec5e.css.map */
